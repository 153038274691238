import React, { useState } from 'react';
import Header from './Header'; 

const AddCustomer = () => {
    const [customerName, setCustomerName] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');
    const [customerFirstName, setCustomerFirstName] = useState(''); // Neues Feld für Vorname
    const [customerLastName, setCustomerLastName] = useState(''); // Neues Feld für Nachname
    const [customerPhone, setCustomerPhone] = useState(''); // Neues Feld für Telefonnummer
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(null);

        const response = await fetch('https://api.fl-itservice.de/add_customer.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                name: customerName, 
                email: customerEmail,
                firstname: customerFirstName, // Vorname hinzufügen
                lastname: customerLastName, // Nachname hinzufügen
                phone: customerPhone // Telefonnummer hinzufügen
            }),
        });

        if (response.ok) {
            setSuccess('Kunde erfolgreich hinzugefügt!');
            setCustomerName('');
            setCustomerEmail('');
            setCustomerFirstName(''); // Eingabefeld zurücksetzen
            setCustomerLastName(''); // Eingabefeld zurücksetzen
            setCustomerPhone(''); // Eingabefeld zurücksetzen
        } else {
            setError('Fehler beim Hinzufügen des Kunden.');
        }
        setLoading(false);
    };

    return (
        <div>
            <Header />
            <div style={styles.container}>
                <h2 style={styles.title}>Neuen Kunden anlegen</h2>
                <form onSubmit={handleSubmit} style={styles.form}>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Vorname:</label>
                        <input
                            type="text"
                            value={customerFirstName}
                            onChange={(e) => setCustomerFirstName(e.target.value)}
                            style={styles.input}
                            required
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Nachname:</label>
                        <input
                            type="text"
                            value={customerLastName}
                            onChange={(e) => setCustomerLastName(e.target.value)}
                            style={styles.input}
                            required
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>E-Mail:</label>
                        <input
                            type="email"
                            value={customerEmail}
                            onChange={(e) => setCustomerEmail(e.target.value)}
                            style={styles.input}
                            required
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Telefon:</label>
                        <input
                            type="tel"
                            value={customerPhone}
                            onChange={(e) => setCustomerPhone(e.target.value)}
                            style={styles.input}
                            required
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Firma:</label>
                        <input
                            type="text"
                            value={customerName}
                            onChange={(e) => setCustomerName(e.target.value)}
                            style={styles.input}
                            required
                        />
                    </div>
                    <button type="submit" style={styles.button} disabled={loading}>
                        {loading ? 'Wird hinzugefügt...' : 'Kunden hinzufügen'}
                    </button>
                    {error && <div style={styles.error}>{error}</div>}
                    {success && <div style={styles.success}>{success}</div>}
                </form>
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '80vh',
    },
    title: {
        marginBottom: '20px',
        fontSize: '24px',
        color: '#333',
    },
    form: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0, 0 , 0, 0.1)',
        minWidth: '500px',
    },
    formGroup: {
        marginBottom: '15px',
    },
    label: {
        display: 'block',
        marginBottom: '5px',
        fontWeight: 'bold',
    },
    input: {
        width: '100%',
        padding: '10px',
        borderRadius: '4px',
        border: '1px solid #ccc',
    },
    button: {
        padding: '10px 15px',
        backgroundColor: '#28a745',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    error: {
        color: 'red',
        marginTop: '10px',
    },
    success: {
        color: 'green',
        marginTop: '10px',
    },
};

export default AddCustomer;