import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header'; 

const CustomerTable = () => {
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const response = await fetch('https://api.fl-itservice.de/customers.php');
                if (!response.ok) {
                    throw new Error('Fehler beim Abrufen der Kunden.');
                }
                const data = await response.json();
                setCustomers(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCustomers();
    }, []);

    if (loading) {
        return <div>Lade...</div>;
    }

    if (error) {
        return <div>Fehler: {error}</div>;
    }

    return (
        <div>
        <Header />
        <div style={styles.container}>
                <h2 style={styles.title}>Kunden</h2>
                <Link to="/add-customer" style={styles.buttonLink}>
                <button style={styles.button}>Kunde anlegen</button>
                </Link>
            <table class="table table-light table-striped">
                <thead>
                    <tr>
                        <th style={styles.th}>ID</th>
                        <th style={styles.th}>Name</th>
                        <th style={styles.th}>Vorname</th>
                        <th style={styles.th}>Nachname</th>
                        <th style={styles.th}>Telefon</th>
                        <th style={styles.th}>E-Mail</th>
                    </tr>
                </thead>
                <tbody>
                    {customers.map(customer => (
                        <tr key={customer.id}>
                            <td style={styles.td}>{customer.id}</td>
                            <td style={styles.td}>{customer.name}</td>
                            <td style={styles.td}>{customer.firstname}</td>
                            <td style={styles.td}>{customer.lastname}</td>
                            <td style={styles.td}>{customer.phone}</td>
                            <td style={styles.td}>{customer.email}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'start',
        padding: '1rem',
    },
    title: {
        marginBottom: '20px',
        fontSize: '24px',
        color: '#333',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        backgroundColor: '#fff',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    },
    button: {
        padding: '5px 8px',
        borderRadius: '4px',
        border: 'none',
        backgroundColor: '#007bff',
        color: '#fff',
        fontSize: '16px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        margin: '1rem',
    },
};

export default CustomerTable;