// Header.js
import React from 'react';

const Sidebar = () => {

    return (
        <div>
        <div class="sidebar">
          <div class="sidebar-wrapper">
            <ul class="nav">
              <li class="">
                <a href="/">
                  <p>Dashboard</p>
                </a>
              </li>
              <li class="">
                <a href="/">
                  <p>Kunden</p>
                </a>
              </li>
              <li class="">
                <a href="/">
                  <p>Clients</p>
                </a>
              </li>
              <li class="">
                <a href="/">
                  <p>Einstellungen</p>
                </a>
              </li>
              <li class="">
                <a href="/">
                  <p>Benutzer</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
        </div>
        
    );
};

export default Sidebar;