import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from './Header'; 

const EditClient = () => {
    const { client_name } = useParams();
    const [clientData, setClientData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [customers, setCustomers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [cpuWarning, setCpuWarning] = useState(''); // Warnwert für CPU
    const [ramWarning, setRamWarning] = useState(''); // Warnwert für RAM
    const [diskWarning, setDiskWarning] = useState(''); // Warnwert für Disk
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    useEffect(() => {
        const fetchClientData = async () => {
            const response = await fetch(`https://api.fl-itservice.de/edit.php?client=${client_name}`);
            if (response.ok) {
                const data = await response.json();
                setClientData(data);
                setSelectedCustomer(data.customer_id); // Setze den aktuellen Kunden
                setSelectedCategory(data.categorie); // Setze die aktuelle Kategorie
                setCpuWarning(data.cpu_warning || ''); // Setze den aktuellen Warnwert für CPU
                setRamWarning(data.ram_warning || ''); // Setze den aktuellen Warnwert für RAM
                setDiskWarning(data.disk_warning || ''); // Setze den aktuellen Warnwert für Disk
            } else {
                console.error('Fehler beim Abrufen der Client-Daten:', response.statusText);
            }
            setLoading(false);
        };

        const fetchCustomers = async () => {
            const response = await fetch('https://api.fl-itservice.de/get_customers.php');
            if (response.ok) {
                const data = await response.json();
                setCustomers(data);
            } else {
                console.error('Fehler beim Abrufen der Kunden:', response.statusText);
            }
        };

        const fetchCategories = async () => {
            const response = await fetch('https://api.fl-itservice.de/get_categories.php');
            if (response.ok) {
                const data = await response.json();
                setCategories(data);
            } else {
                console.error('Fehler beim Abrufen der Kategorien:', response.statusText);
            }
        };

        fetchClientData();
        fetchCustomers();
        fetchCategories();
    }, [client_name]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch(`https://api.fl-itservice.de/edit.php?client=${clientData.computer_name}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                customer_id: selectedCustomer, 
                categorie_name: selectedCategory,
                cpu_warning: cpuWarning, 
                ram_warning: ramWarning,
                disk_warning: diskWarning 
            }),
        });

        if (response.ok) {
            setSuccess('Änderungen gespeichert!');
        } else {
            setError('Fehler beim Speichern der Änderungen.');
        }
    };

    if (loading) {
        return <div>Lade...</div>;
    }

    if (!clientData) {
        return <div>Client nicht gefunden</div>;
    }

    return (
        <div>
            <Header />
            <div style={styles.container}>
                <h2 style={styles.title}>Bearbeiten von {clientData.computer_name}</h2>
                <form onSubmit={handleSubmit} style={styles.form}>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Kunde:</label>
                        <select value={selectedCustomer} onChange={(e) => setSelectedCustomer(e.target.value)} style={styles.select}>
                            {customers.map(customer => (
                                <option key={customer.id} value={customer.id}>{customer.name}</option>
                            ))}
                        </select>
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Kategorie:</label>
                        <select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)} style={styles.select}>
                            {categories.map(category => (
                                <option key={category.id} value={category.name}>{category.name}</option>
                            ))}
                        </select>
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>CPU Warnwert (%):</label>
                        <input
                            type="number"
                            value={cpuWarning}
                            onChange={(e) => setCpuWarning(e.target.value)}
                            style={styles.input}
                            required
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>RAM Warnwert (%):</label>
                        <input
                            type="number"
                            value={ramWarning}
                            onChange={(e) => setRamWarning(e.target.value)}
                            style={styles.input}
                            required
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Disk Warnwert (%):</label>
                        <input
                            type="number"
                            value={diskWarning}
                            onChange={(e) => setDiskWarning(e.target.value)}
                            style={styles.input}
                            required
                        />
                    </div>
                    <button type="submit" style={styles.button}>Änderungen speichern</button>
                    {error && <div style={styles.error}>{error}</div>}
                    {success && <div style={styles.success}>{success}</div>}
                </form>
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '80vh',
    },
    title: {
        marginBottom: '20px',
        fontSize: '24px',
        color: '#333',
    },
    form: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0, 0 , 0, 0.1)',
        minWidth: '500px',
    },
    formGroup: {
        marginBottom: '15px',
    },
    label: {
        display: 'block',
        marginBottom: '5px',
        fontWeight: 'bold',
    },
    input: {
        width: '100%',
        padding: '10px',
        borderRadius: '4px',
        border: '1px solid #ccc',
    },
    select: {
        width: '100%',
        padding: '10px',
        borderRadius: '4px',
        border: '1px solid #ccc',
    },
    button: {
        padding: '10px 15px',
        backgroundColor: '#28a745',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    error: {
        color: 'red',
        marginTop: '10px',
    },
    success: {
        color: 'green',
        marginTop: '10px',
    },
};

export default EditClient;