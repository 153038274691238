// Header.js
import React from 'react';
import './Header.css';

const Header = () => {
    const getUsernameFromToken = () => {
        const token = localStorage.getItem('token'); // Ersetze 'token' durch den tatsächlichen Schlüssel
        if (token) {
            const payload = JSON.parse(atob(token.split('.')[1])); // Dekodiere den Payload
            return payload.data.firstname; // Ersetze 'username' durch den tatsächlichen Schlüssel im Payload
        }
        return null;
    };

    const username = getUsernameFromToken();

    return (
        <div>
        <nav class="navbar navbar-expand-lg navbar-absolute navbar-transparent">
            <div class="container-fluid">
            <div class="navbar-wrapper">
              <div class="navbar-toggle d-inline">
                <button type="button" class="navbar-toggler">
                  <span class="navbar-toggler-bar bar1"></span>
                  <span class="navbar-toggler-bar bar2"></span>
                  <span class="navbar-toggler-bar bar3"></span>
                </button>
              </div>
              <a class="navbar-brand" href="/"><img src="/flitservice_logo_light.png" alt="Logo" height="40" className="me-2" /></a>
            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-bar navbar-kebab"></span>
              <span class="navbar-toggler-bar navbar-kebab"></span>
              <span class="navbar-toggler-bar navbar-kebab"></span>
            </button>
            <div class="collapse navbar-collapse" id="navigation">
              <ul class="navbar-nav ml-auto">
                <li class="search-bar input-group">
                  <button class="btn btn-link" id="search-button" data-toggle="modal" data-target="#searchModal"><i class="tim-icons icon-zoom-split" ></i>
                    <span class="d-lg-none d-md-block">Search</span>
                  </button>
                </li>
                
                <li class="dropdown nav-item">
                  <a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown">
                    <b class="caret d-none d-lg-block d-xl-block ml-2"></b>
                     {username}
                  </a>
                  <ul class="dropdown-menu dropdown-navbar">
                    <li class="nav-link"><a href="" class="nav-item dropdown-item">Profil</a></li>
                    <li class="nav-link"><a href="" class="nav-item dropdown-item">Einstellungen</a></li>
                    <li class="dropdown-divider"></li>
                    <li class="nav-link"><a href="/logout" class="nav-item dropdown-item">Abmelden</a></li>
                  </ul>
                </li>
                <li class="separator d-lg-none"></li>
              </ul>
            </div>
          </div>
        </nav>
        </div>
        
    );
};

export default Header;