import React, { useState } from 'react';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch('https://api.fl-itservice.de/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem('token', data.access_token); // Token speichern
        onLogin(username); // Bei erfolgreichem Login
      } else {
        setError(data.message); // Fehlernachricht anzeigen
      }
    } catch (error) {
      setError('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
    }
  };

  return (
    <div>
      <div style={styles.container}>
        <div style={styles.login}>
          <div class="row">
          <a class="navbar-brand" href="/"><img src="/flitservice_logo_light.png" alt="Logo" height="40" className="me-2" /></a>
                <div class="card m-2">
                  <div class="card-header">
                    <h2 class="card-title">Health & Care Center</h2>
                  </div>
                <div class="card-body">
                  <form onSubmit={handleSubmit}>
                    <div class="form-group">
                      <label for="exampleInputEmail1">Email</label>
                      <input type="email" value={username} onChange={(e) => setUsername(e.target.value)} class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email"></input>
                    </div>
                    <div class="form-group">
                      <label for="exampleInputPassword1">Passwort</label>
                      <input type="password" value={password}  onChange={(e) => setPassword(e.target.value)}  class="form-control" id="exampleInputPassword1" placeholder="Passwort"></input>
                    </div>
                    {error && 
                          <div class="alert alert-danger" role="alert">
                          {error}
                          </div>
                    } {/* Fehleranzeige */}
                    <button type="submit" class="btn btn-primary">Anmelden</button>
                  </form>
                </div>
        </div>
        </div>
        </div>
      </div>
      </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
  },
  login: {
    minWidth: '150px',
    padding: '10px',
    margin: '10px',
  },
};

// Fügen Sie den Hover-Effekt im CSS hinzu
const Button = ({ children, ...props }) => (
  <button
    {...props}
    style={{ ...styles.button, ':hover': styles.buttonHover }}
  >
    {children}
  </button>
);

export default Login;